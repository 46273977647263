import React from 'react'
import { graphql } from 'gatsby'
import tw from 'twin.macro'
// import contentParser from 'gatsby-wpgraphql-inline-images'

import Layout from '../../components/Layout'
import SEO from '../../components/Seo'
import Container from '../../components/Container'
import { inlineImagesOptions } from '../../helpers'

const PageTemplate = ({data}) => {
    const page = data.allWpPage.nodes[0]

    return (
        <>
            <SEO />
            <Layout>
              <Container tw="py-12">
                <div tw="prose max-w-full" dangerouslySetInnerHTML={{ __html: page.content }}>
                  {/* {contentParser({ content: page.content }, inlineImagesOptions )} */}
                </div>
              </Container>
            </Layout>
        </>
    )
}

export default PageTemplate

export const query = graphql`
  query($id: String!) {
    allWpPage(filter: { id: { eq: $id } }) {
      nodes {
        title
        content
      }
    }
  }
`